@import '/apps/wt/webtools/sparta_build_server/tmp/9C7439D6/artifacts/components/utilities/global/sparta-style-utility/4.9.5/scss/_sparta-project.scss';
$moduleNameSpace: dynamic-name-space;




[data-sparta-container] .seo-title-button-module {
  display: none;
  position: absolute;
  right: 113px;
  top: 0;

  @media #{$medium-only} {
    right: 10px;
  }

  @media #{$medium-up} {
    display: block;
  }

  .spa-btn {
    &--white-border {
      border: 1px solid $color-brand-tertiary;
      color: $color-brand-tertiary;
      line-height: 1;

      &:focus,
      &:hover {
        background-color: $color-brand-tertiary;
        color: $color-royal-one;
      }
    }
  }
}
